<template>
  <div id="blog" class="content">
    <div class="container detill-content detill-blog">
      <div class="link-forward">
        <router-link :to="{ name: 'home' }">
          <span>Home</span>
        </router-link>
        <router-link :to="{ name: 'blog' }">
          <span>Blog</span>
        </router-link>
      </div>
      <p class="title-detailblog">{{ dataById.title }}</p>
      <el-card class="box-card card-detail-blog" shadow="always">
        <div slot="header" class="clearfix">
          <!-- <img
            class="image-blog"
            :src="require(`@/assets/image/blog${blogId}.png`)"
          /> -->
        </div>
        <div class="detail-blog" v-html="dataById.html"></div>
        <div class="footer-blog">
          <el-row :gutter="30" class="set-count-color-card">
            <el-col :span="24" :sm="24" :md="8" class="show-category">
              <!-- <pre>{{Blog}}</pre> -->
              <div class="category-blog" v-if="loadData == true">
                <span>Category: </span>
                <p v-for="(category, index) in dataById.category" :key="index">
                  <span
                    class="type1"
                    v-if="category.name == 'service'"
                    @click="selectCategory('service')"
                    >Service</span
                  >
                  <span
                    class="type2"
                    v-if="category.name == 'innovation-2'"
                    @click="selectCategory('innovation-2')"
                    >Innovation</span
                  >
                  <span
                    class="type3"
                    v-if="category.name == 'business'"
                    @click="selectCategory('business')"
                    >Business</span
                  >
                  <span
                    class="type4"
                    v-if="category.name == 'startup'"
                    @click="selectCategory('startup')"
                    >Startup</span
                  >
                  <span
                    class="type5"
                    v-if="category.name == 'article'"
                    @click="selectCategory('article')"
                    >Article</span
                  >
                  <span
                    class="type6"
                    v-if="category.name == 'event'"
                    @click="selectCategory('event')"
                    >Event</span
                  >
                  <span
                    class="type7"
                    v-if="category.name == 'news'"
                    @click="selectCategory('news')"
                    >News</span
                  >
                </p>
              </div>
            </el-col>
            <el-col :span="24" :sm="12" :md="8" class="mt-5">
              <p class="time">
                SMID <span>{{ dataById.updated_at | timeTh }}</span>
              </p></el-col
            >
            <el-col :span="24" :sm="12" :md="8" class="mt-5">
              <span>Share : </span>
              <span class="share-facebook">
                <ShareNetwork
                  :network="'facebook'"
                  :key="'facebook'"
                  :url="baseUrl + $route.path"
                  :title="
                    typeof dataById.title != 'undefined' ? dataById.title : ''
                  "
                  :description="dataById.description"
                  :quote="
                    typeof dataById.title != 'undefined' ? dataById.title : ''
                  "
                >
                  <i class="fab fa-facebook-f"></i>
                </ShareNetwork>
              </span>
              <!-- if (typeof this.dataById.title == "undefined") {
              this.dataById.title = "";
            } -->
              <span class="share-email">
                <ShareNetwork
                  :network="'email'"
                  :key="'email'"
                  :url="baseUrl + $route.path"
                  :title="
                    typeof dataById.title != 'undefined' ? dataById.title : ''
                  "
                  :description="dataById.description"
                  :quote="
                    typeof dataById.title != 'undefined' ? dataById.title : ''
                  "
                >
                  <i class="far fa-envelope"></i> </ShareNetwork
              ></span>
            </el-col>
          </el-row>
        </div>
      </el-card>
      <div class="box-suggest">
        <p class="title-suggest">บทความแนะนำ</p>
        <el-row :gutter="30" class="set-count-color-card">
          <el-col
            class="set-mt-blog"
            :span="24"
            :sm="12"
            :md="8"
            v-for="(dataBlogs, index) in randomBlog(Blog)"
            :key="index"
          >
            <cardBlog :dataBlog="dataBlogs" @dataCategory="selectCategory" />
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import cardBlog from "@/components/cardBlog";
import { BLOG } from "@/service/axios.js";
export default {
  components: {
    cardBlog,
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      blogId: this.$route.params.blogId,
      Blog: [],
      dataById: [],
      loadData: false,
      thisData: [],
    };
  },
  async mounted() {
    this.getBlog();
    await this.getBlogById();
  },
  methods: {
    selectCategory(val) {
      this.$router.push(`/blog?type=${val}`);
      // console.log(val);
    },
    randomBlog(arr = []) {
      console.log(arr);
      let cut = arr.findIndex((a) => a.id == this.blogId);
      if (cut > -1) {
        arr = arr.splice(cut, 1);
      }
      if (arr.length > 3) {
        arr = arr.slice(0, 3);
      }
      return arr;
    },
    getBlogById() {
      BLOG.get(`posts/${this.blogId}?key=78503f5d740a46935840ac886a`)
        .then(async (res) => {
          if (res.status == 200) {
            this.dataById = res.data.posts[0];
            this.dataById.category = [];
            console.log("getBlogById", this.dataById);
            await BLOG.get(`tags?key=78503f5d740a46935840ac886a`)
              .then(async (res) => {
                if (res.status == 200) {
                  var tags = res.data.tags;
                  for (var i = 0; i < tags.length; i++) {
                    var changeString = tags[i].slug;
                    await BLOG.get(
                      `posts/?key=78503f5d740a46935840ac886a&filter=tag:${changeString}`
                    )
                      .then((res) => {
                        if (res.status == 200) {
                          var checkObj = res.data.posts.find(
                            (e) => e.id === this.dataById.id
                          );
                          if (checkObj != undefined) {
                            var getCategory = { name: changeString };
                            this.dataById.category.push(getCategory);
                          }
                        } else {
                          this.alertFailError();
                        }
                      })
                      .catch((e) => {
                        this.alertCatchError(e);
                        console.log("get blog error");
                      });
                  }
                  this.loadData = true;
                } else {
                  this.alertFailError();
                }
              })
              .catch((e) => {
                this.alertCatchError(e);
                console.log("get blog error");
              });
          } else {
            this.alertFailError();
          }
        })
        .catch((e) => {
          this.alertCatchError(e);
          console.log("get blog error");
        });
    },
    getBlog() {
      BLOG.get(`posts?key=78503f5d740a46935840ac886a&page=1&limit=7`)
        .then(async (res) => {
          if (res.status == 200) {
            this.thisData = res.data.posts;
            for (var a = 0; a < this.thisData.length; a++) {
              this.thisData[a].category = [];
              // if(this.thisData[a].id === blogId) {
              //   this.thisData[a]
              // }
              // var checkObj = this.blogId.find(
              //   (e) => e === this.thisData[a].id
              // );
              // if (checkObj != undefined) {
              //   console.log('dd');
              // }
            }
            await BLOG.get(`tags?key=78503f5d740a46935840ac886a`)
              .then(async (res) => {
                if (res.status == 200) {
                  var tags = res.data.tags;
                  for (var i = 0; i < tags.length; i++) {
                    var changeString = tags[i].slug;
                    await BLOG.get(
                      `posts/?key=78503f5d740a46935840ac886a&filter=tag:${changeString}`
                    )
                      .then((res) => {
                        if (res.status == 200) {
                          for (var y = 0; y < this.thisData.length; y++) {
                            var checkObj = res.data.posts.find(
                              (e) => e.id === this.thisData[y].id
                            );
                            if (checkObj != undefined) {
                              // console.log("checkObj undefined: ", checkObj);
                              var getCategory = { name: changeString };
                              this.thisData[y].category.push(getCategory);
                            }
                          }
                        } else {
                          this.alertFailError();
                        }
                      })
                      .catch((e) => {
                        this.alertCatchError(e);
                        console.log("get blog error");
                      });
                  }
                } else {
                  this.alertFailError();
                }
              })
              .catch((e) => {
                this.alertCatchError(e);
                console.log("get blog error");
              });
            this.Blog = this.thisData;
          } else {
            this.alertFailError();
          }
        })
        .catch((e) => {
          this.alertCatchError(e);
          console.log("get blog error");
        });
    },
  },
};
</script>
<style lang="scss">
figure.kg-card {
  &.kg-image-card {
    margin: 0;
    img:first-child{
      width: 100%!important;
    }
    img {
      width: auto;
      max-width: 100%;
      height: auto;
    }
  }
  &.kg-embed-card {
    margin: 0;
    iframe {
      width: 100%;
      max-width: 100%;
      min-height: 520px;
      height: auto;
      @media (max-width: 575.98px) {
        min-height: 158px;
        height: auto;
      }
    }
  }
}
.card-detail-blog .footer-blog .category-blog span {
  margin-top: 4px !important;
}
li {
  word-break: break-word;
}
.show-category {
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
